import { MediaProvider, Poster, useMediaStore } from '@vidstack/react';
import { shape } from 'airbnb-prop-types';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';

import '@vidstack/react/player/styles/default/layouts/video.css';
import '@vidstack/react/player/styles/default/theme.css';

import { Pause } from '@common/components/Icons';
import {
  MediaPlayerStyled,
  PlayPauseControl,
  PlayStyled,
  VideoPlayerStyled,
} from '@common/components/VideoPlayer/VideoPlayer.styled';

export const VideoPlayer = ({ options, isLookbookTeaser }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const playerRef = useRef(null);
  const { paused } = useMediaStore(playerRef);

  const onTogglePlay = useCallback(
    e => {
      e.preventDefault();

      if (playerRef.current) {
        if (paused) {
          playerRef.current.play();
        } else {
          playerRef.current.pause();
        }
      }
    },
    [paused, playerRef]
  );

  return (
    <VideoPlayerStyled>
      {isVideoLoaded && (
        <PlayPauseControl
          onClick={onTogglePlay}
          topAligned={isLookbookTeaser}
        >
          {!paused ? <Pause width="14px" /> : <PlayStyled width="14px" />}
        </PlayPauseControl>
      )}
      <MediaPlayerStyled
        ref={playerRef}
        autoplay
        src={options.source}
        muted={options.muted}
        playsinline={options.playsinline}
        onLoadedData={() => setIsVideoLoaded(true)}
        loop={options.loop}
      >
        <MediaProvider />
        {!isVideoLoaded && <Poster src={options.poster} />}
      </MediaPlayerStyled>
    </VideoPlayerStyled>
  );
};

VideoPlayer.propTypes = {
  options: shape({
    autoplay: PropTypes.bool,
    controls: PropTypes.bool,
    loop: PropTypes.bool,
    muted: PropTypes.bool,
    disablePictureInPicture: PropTypes.bool,
    poster: PropTypes.string,
    playsinline: PropTypes.bool,
    source: shape({
      src: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
  isLookbookTeaser: PropTypes.bool,
};

VideoPlayer.defaultProps = {
  options: {},
  isLookbookTeaser: false,
};

export default VideoPlayer;
